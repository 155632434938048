.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &--focusable:active,
  &--focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}

.xs-sr-only {
  @media (max-width: $screen-xs-max) {
    clip: rect(1px, 1px, 1px, 1px) !important;
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.valign-center {
  align-self: center;
}
