// #region COLOURS
// Greys
$color-white: #fff;
$color-grey-light: #f5f5f5;
$color-grey-light-mid: #e4e4e4;
$color-grey-mid: #ccc;
$color-grey-dark: #707070;
$color-grey-darker: #333;

/* Primary level colours */
$color-primary: #d42114;
$color-primary-light: #ffeeed;
$color-primary-dark: #7f150d;

$color-secondary: #008542;
$color-secondary-light: #edfff6;
$color-secondary-dark: #045228;

$color-tertiary: #0c509f;
$color-tertiary-light: #e4f1ff;
$color-tertiary-dark: #08305f;

/* End Primary level colours */

/* Secondary level colours */
$color-yellow: #efc71d;
$color-star-fill: $color-yellow;
$color-star-stroke: #bf9f18;

$color-cranberry: #d02647;
$color-navy: #083467;
$color-cerulean: #69c;
$color-nectar-purple-a-dark: #471e5e;
$color-nectar-purple-b-dark: #912385;
$color-teal: #008578;
$color-gunmetal: #4f5a65;
$color-mauve: #781f74;

// #region Depricated
$color-black: #000;
$color-grey-charcoal: #333;
$color-grey-gunmetal: $color-gunmetal;
$color-clearance: $color-yellow;
$color-sainsburys: #f39000;
$color-sainsburys-purple: #62343f;
$color-red: $color-primary;
$color-rust: $color-primary;
$color-orange: $color-primary;
$color-dark-navy: $color-navy;
$color-blue-cobalt: $color-navy;
$color-cerulean-light: $color-cerulean;
$color-green: $color-secondary;
$color-green-dark: $color-secondary;
$color-green-forest: $color-secondary;
$color-green-kelly: $color-secondary;
$color-nectar-purple-a-mid: #6c4b7e;
$color-nectar-purple-a-light: #a793b2;
$color-nectar-purple-b-mid: #a74f9d;
$color-nectar-purple-b-light: #ca95c4;
$color-aubergine: $color-nectar-purple-a-dark;
$color-mauve-mid: $color-mauve;
$color-mauve-light: $color-mauve;
$color-raw-sienna: #95664c;
$color-alert-teal: #e5f3ec;
$color-alert-orange: #fef4e8;
$color-alert-blue: #dbe4ee;
// #endregion Deprecated */
// #endregion COLOURS

// #region SCREEN SIZES */
$screen-xs-min: 320px;
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;

$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: 1200px;

// #endregion SCREEN SIZES */

// #region TYPOGRAPHY */
$font-family: 'Barlow', sans-serif;
$font-weight-normal: 400;
$font-weight-bold: 600;

$font-size-base: 16px;
$font-size-display: 3rem;
$font-size-h1: 2.25rem;
$font-size-h2: 1.875rem;
$font-size-h3: 1.5rem;
$font-size-h4: 1.25rem;
$font-size-caption: 0.75rem;

// Mobile font sizes
$font-size-display-mobile: 2.25rem;
$font-size-h1-mobile: 1.875rem;
$font-size-h2-mobile: 1.5rem;
$font-size-h3-mobile: 1.25rem;

$margin-bottom: 0.625rem;

$line-height-base: 1.5rem;
$line-height-display: 3.375rem;
$line-height-h1: 2.625rem;
$line-height-h2: 2.25rem;
$line-height-h3: 2rem;
$line-height-h4: 1.75rem;
$line-height-caption: 1rem;

$letter-spacing-base: normal;

$line-height-computed: floor(($font-size-base * $line-height-base));

@mixin argos-font {
  font-family: $font-family;
  font-display: swap;
}

.font-standard {
  @include argos-font;

  font-weight: $font-weight-normal;
}

.font-bold {
  @include argos-font;

  font-weight: $font-weight-bold;
}

.font-small {
  @extend .font-standard;

  font-size: $font-size-caption;
}

// #endregion TYPOGRAPHY */

// #region FORMS */
$control-height-base: 44px;
// #endregion FORMS */

// #region BORDERS */
$border-base: 1px solid $color-grey-mid;
$border-radius-base: 3px;
// #endregion BORDERS */

// #region ANIMATION */
$transition-duration: 0.2s;
$transition-ease: ease;
$transition-ease-out: cubic-bezier(0.42, 0, 0, 1.07);
$base-transition: $transition-duration $transition-ease;
$ease-out-transition: $transition-duration $transition-ease-out;
// #endregion ANIMATION */

// #region MISC */
@mixin iPhone-X {
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}

// https://stackoverflow.com/a/27315792/608290
// IE 10 and above - The use of -ms-high-contrast means that MS Edge will not be targeted,
// as Edge does not support -ms-high-contrast.
@mixin ie11-override {
  @media (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

// From Store-Selector
@mixin clearfix() {
  &::after {
    clear: both;
  }

  &::after,
  &::before {
    content: '';
    display: table;
  }
}
// #endregion MISC */

/* stylelint-disable */
:export {
  /* Colours */
  color_primary: $color-primary;
  color_primary_light: $color-primary-light;
  color_primary_dark: $color-primary-dark;
  color_secondary: $color-secondary;
  color_secondary_light: $color-secondary-light;
  color_secondary_dark: $color-secondary-dark;
  color_tertiary: $color-tertiary;
  color_tertiary_light: $color-tertiary-light;
  color_tertiary_dark: $color-tertiary-dark;
  color_white: $color-white;
  color_grey_light: $color-grey-light;
  color_grey_light_mid: $color-grey-light-mid;
  color_grey_mid: $color-grey-mid;
  color_grey_dark: $color-grey-dark;
  color_grey_darker: $color-grey-darker;
  color_yellow: $color-yellow;
  color_star_fill: $color-star-fill;
  color_star-stroke: $color-star-stroke;
  color_navy: $color-navy;
  color_cranberry: $color-cranberry;
  color_cerulean: $color-cerulean;
  color_nectar_purple_a_dark: $color-nectar-purple-a-dark;
  color_nectar_purple_b_dark: $color-nectar-purple-b-dark;
  color_teal: $color-teal;
  color_gunmetal: $color-gunmetal;
  color_mauve: $color-mauve;

  /* Fonts */
  font_family: $font-family;
  font_weight: $font-weight-normal;
  font_weight_bold: $font-weight-bold;
  font_size_caption: $font-size-caption;
  font_size_base: $font-size-base;
  font_size_h2: $font-size-h2;
  font_size_h3: $font-size-h3;
  font_size_h4: $font-size-h4;
  line_height_base: $line-height-base;

  /* Animations */
  base_transition: $base-transition;
  ease_out_transition: $ease-out-transition;

  /* Media Queries */
  screen_xs_min: $screen-xs-min;
  screen_sm_min: $screen-sm-min;
  screen_md_min: $screen-md-min;
  screen_lg_min: $screen-lg-min;
  screen_xs_max: $screen-xs-max;
  screen_sm_max: $screen-sm-max;
  screen_md_max: $screen-md-max;
  screen_lg_max: $screen-lg-max;

  /* CMS Magnolia */
  color_grey_charcoal: $color-grey-charcoal;
  color_red: $color-primary;
  color_rust: $color-primary;
  color_mauve: $color-mauve;
  color_mauve_mid: $color-mauve;
  color_mauve_light: $color-mauve;
  color_dark_navy: $color-navy;
  color_blue_cobalt: $color-navy;
  color_cerulean: $color-cerulean;
  color_cerulean_light: $color-cerulean;
  color_green: $color-secondary;
  color_green_kelly: $color-secondary;
  color_teal: $color-teal;
  color_raw_sienna: $color-raw-sienna;

  /* Misc */
  border_base: $border_base;
  border_radius_base: $border-radius-base;
}
