@import '../abstracts/variables';

// #region COLOURS
.color-white {
  color: $color-white;
}

.color-grey-light {
  color: $color-grey-light;
}

.color-grey-light-mid {
  color: $color-grey-light-mid;
}

.color-grey-mid {
  color: $color-grey-mid;
}

.color-grey-dark {
  color: $color-grey-dark;
}

.color-grey-darker {
  color: $color-grey-darker;
}

/* Primary level colours */
.color-primary {
  color: $color-primary;
}

.color-primary-light {
  color: $color-primary-light;
}

.color-primary-dark {
  color: $color-primary-dark;
}

.color-secondary {
  color: $color-secondary;
}

.color-secondary-light {
  color: $color-secondary-light;
}

.color-secondary-dark {
  color: $color-secondary-dark;
}

.color-tertiary {
  color: $color-tertiary;
}

.color-tertiary-light {
  color: $color-tertiary-light;
}

.color-tertiary-dark {
  color: $color-tertiary-dark;
}

/* End Primary level colours */

/* Secondary level colours */
.color-yellow {
  color: $color-yellow;
}

.color-star-fill {
  color: $color-star-fill;
}

.color-star-stroke {
  color: $color-star-stroke;
}

.color-cranberry {
  color: $color-cranberry;
}

.color-navy {
  color: $color-navy;
}

.color-cerulean {
  color: $color-cerulean;
}

.color-nectar-purple-a-dark {
  color: $color-nectar-purple-a-dark;
}

.color-nectar-purple-b-dark {
  color: $color-nectar-purple-b-dark;
}

.color-teal {
  color: $color-teal;
}

.color-gunmetal {
  color: $color-gunmetal;
}

.color-mauve {
  color: $color-mauve;
}

// #endregion COLOURS

// #region BG COLOURS
.bg-white {
  background-color: $color-white;
}

.bg-grey-light {
  background-color: $color-grey-light;
}

.bg-grey-light-mid {
  background-color: $color-grey-light-mid;
}

.bg-grey-mid {
  background-color: $color-grey-mid;
}

.bg-grey-dark {
  background-color: $color-grey-dark;
}

.bg-grey-darker {
  background-color: $color-grey-darker;
}

/* Primary level colours */
.bg-primary {
  background-color: $color-primary;
}

.bg-primary-light {
  background-color: $color-primary-light;
}

.bg-primary-dark {
  background-color: $color-primary-dark;
  color: $color-white;
}

.bg-secondary {
  background-color: $color-secondary;
}

.bg-secondary-light {
  background-color: $color-secondary-light;
}

.bg-secondary-dark {
  background-color: $color-secondary-dark;
  color: $color-white;
}

.bg-tertiary {
  background-color: $color-tertiary;
}

.bg-tertiary-light {
  background-color: $color-tertiary-light;
}

.bg-tertiary-dark {
  background-color: $color-tertiary-dark;
  color: $color-white;
}

/* End Primary level colours */

/* Secondary level colours */
.bg-yellow {
  background-color: $color-yellow;
}

.bg-star-fill {
  background-color: $color-star-fill;
}

.bg-star-stroke {
  background-color: $color-star-stroke;
}

.bg-cranberry {
  background-color: $color-cranberry;
}

.bg-navy {
  background-color: $color-navy;
}

.bg-cerulean {
  background-color: $color-cerulean;
}

.bg-nectar-purple-a-dark {
  background-color: $color-nectar-purple-a-dark;
}

.bg-nectar-purple-b-dark {
  background-color: $color-nectar-purple-b-dark;
}

.bg-teal {
  background-color: $color-teal;
}

.bg-gunmetal {
  background-color: $color-gunmetal;
}

.bg-mauve {
  background-color: $color-mauve;
}

// #endregion BG COLOURS
