@use 'sass:math';
@import '../abstracts/variables';

$gutter: 15px;
$columns: 12;

@mixin base-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; // stylelint-disable-line declaration-block-no-redundant-longhand-properties
  align-items: stretch;
}

@mixin base-stack {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap; // stylelint-disable-line declaration-block-no-redundant-longhand-properties
  align-items: stretch;
}

@mixin no-gutters {
  margin-left: 0;
  margin-right: 0;
}

@mixin gutters {
  margin-left: $gutter;
  margin-right: $gutter;
}

@mixin negative-gutters {
  margin-left: -$gutter;
  margin-right: -$gutter;
}

@mixin column-generator($size) {
  .#{$size}-hidden {
    display: none;
  }

  .#{$size}-block {
    display: block;
  }

  .#{$size}-row {
    @include base-row;
  }

  .#{$size}-stack {
    @include base-stack;
  }

  .#{$size}-nowrap {
    flex-wrap: nowrap;
  }

  .#{$size}-wrap {
    flex-wrap: wrap;
  }

  .#{$size}-fit {
    flex-basis: auto;
  }

  .#{$size}-middle {
    align-items: center;
  }

  @for $i from 1 through $columns {
    .#{$size}-#{$i} {
      @include gutters;

      flex: 0 0 auto;
      flex-basis: calc(#{percentage(math.div($i, $columns))} - #{$gutter * 2});
      max-width: calc(#{percentage(math.div($i,  $columns))} - #{$gutter * 2});
    }

    .#{$size}-#{$i}--none {
      @include no-gutters;

      flex: 0 0 percentage(math.div($i, $columns));
      max-width: percentage(math.div($i, $columns));
    }

    .#{$size}-#{$i}--negative {
      @include negative-gutters;

      flex: 0 0 auto;
      flex-basis: calc(#{percentage(math.div($i, $columns))} + #{$gutter * 2});
      max-width: calc(#{percentage(math.div($i, $columns))} + #{$gutter * 2});
    }

    .#{$size}-order-#{$i} {
      order: #{$i};
    }
  }

  // todo: deprecate as it's the same as .#{$size}-12
  .#{$size}-auto {
    @include gutters;

    flex: 0 0 auto;
    max-width: calc(100% - #{$gutter * 2});
  }

  .#{$size}-auto--none {
    @include no-gutters;

    flex: 0 0 auto;
    max-width: 100%;
  }

  .#{$size}-auto--negative {
    @include negative-gutters;

    flex: 0 0 auto;
    max-width: calc(100% + #{$gutter * 2});
  }
  // end deprecate

  .#{$size}-order-0 {
    order: 0;
  }
}

.container {
  width: 100%;
  max-width: $screen-lg-max;
  min-width: $screen-xs-min;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left: $gutter;
  padding-right: $gutter;
}

.container--row {
  flex-direction: row;
}

@include column-generator('xs');

@media (min-width: $screen-sm-min) {
  @include column-generator('sm');
}

@media (min-width: $screen-md-min) {
  @include column-generator('md');
}

@media (min-width: $screen-lg-min) {
  @include column-generator('lg');
}
