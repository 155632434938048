@import '../abstracts/variables';

$bolt-font-path: '../fonts' !default;

// Everything sits within .bolt-v2 class
.bolt-v2 {
  table {
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $color-grey-charcoal;
    width: 100%;
    text-align: left;

    @extend .font-standard;
  }

  th,
  td {
    padding: 5px;
    border-bottom: 1px solid $color-grey-light-mid;
  }

  th {
    @extend .font-bold;
  }
}
