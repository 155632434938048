@import '../abstracts/variables';

$bolt-font-path: '../fonts' !default;

// Everything sits within .bolt-v2 class
.bolt-v2 {
  font-size: $font-size-base;
  color: $color-grey-charcoal;
  -webkit-font-smoothing: antialiased;

  .display,
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4 {
    margin-bottom: $margin-bottom;

    @extend .font-bold;
  }

  .display {
    font-size: $font-size-display-mobile;

    @media (min-width: $screen-sm-min) {
      font-size: $font-size-display;
    }
  }

  h1,
  .h1 {
    font-size: $font-size-h1-mobile;
    line-height: normal;

    @media (min-width: $screen-sm-min) {
      font-size: $font-size-h1;
    }
  }

  h2,
  .h2 {
    font-size: $font-size-h2-mobile;
    line-height: normal;

    @media (min-width: $screen-sm-min) {
      font-size: $font-size-h2;
    }
  }

  h3,
  .h3 {
    font-size: $font-size-h3-mobile;
    line-height: normal;

    @media (min-width: $screen-sm-min) {
      font-size: $font-size-h3;
    }
  }

  h4,
  .h4 {
    font-size: $font-size-base;
    line-height: normal;

    @media (min-width: $screen-sm-min) {
      font-size: $font-size-h4;
    }
  }

  p,
  li {
    @extend .font-standard;

    line-height: $line-height-base;
  }

  p {
    margin-bottom: $margin-bottom;
    line-height: $line-height-base;

    &.no-line-height {
      line-height: normal;
    }
  }

  a:not([class]) {
    @extend .font-bold;

    color: $color-tertiary;
    text-decoration: underline;

    &:hover,
    &:active {
      color: $color-tertiary-dark;
    }
  }

  strong {
    @extend .font-bold;
  }

  small {
    @extend .font-small;
  }

  em {
    font-style: italic;
  }

  @import url('https://fonts.googleapis.com/css?family=Barlow:400,600&display=swap');
}
