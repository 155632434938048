.sb-show-main {
  background: #fff;
  margin: 20px;
  .font-standard,
  .font-small {
    font-family: 'Barlow', sans-serif;
    font-display: swap;
    font-weight: 400;
  }
  .font-bold {
    font-family: 'Barlow', sans-serif;
    font-display: swap;
    font-weight: 600;
  }
  .font-small {
    font-size: 0.75rem;
  }
  .header {
    width: 100%;
    color: #333;
    margin-bottom: 20px;
  }
  .header h1 {
    margin-top: 10px;
    display: block;
  }
  .header .container {
    display: block;
  }
  section {
    margin-bottom: 90px;
  }
  .display-section {
    background: #f5f5f5;
    padding: 20px;
  }
  .story h1,
  .story h2,
  .story h3 {
    margin: 20px 0;
  }
  .component-inview-story__demo {
    width: 100%;
    height: 150px;
  }
  .component-inview-story__spacing {
    height: 1500px;
  }
  .component-inview-story__heading {
    height: 1500px;
  }
  .component-inview-story__heading small {
    display: block;
  }
  .component-inview-story__bottom {
    width: 100%;
    left: 0;
    bottom: 0;
    display: block;
    position: fixed;
    text-align: center;
  }
  @keyframes bounce {
    0% {
      -webkit-transform: rotate(-45deg) translate(0, 0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate(-45deg) translate(-20px, 20px);
      opacity: 0;
    }
  }
  .component-inview-story__bottom div {
    height: 65px;
  }
  .component-inview-story__bottom span {
    height: 25px;
    width: 25px;
    display: block;
    border-width: 0 0 2px 2px;
    border-style: solid;
    animation: bounce 1.5s infinite;
    border-color: #707070;
    margin: 0 auto;
  }
  .storybook-markdown code {
    background: #f5f5f5;
    padding: 0 3px;
    border-radius: 3px;
  }
  .storybook-markdown ul,
  .storybook-markdown ol {
    list-style: unset;
    margin-left: 2em;
    margin-bottom: 0.625rem;
  }
  .storybook-markdown blockquote > p {
    border-left: 3px solid #d42114;
    padding-left: 8px;
  }
  .storybook-markdown pre {
    background: #f5f5f5;
    border: 1px solid #ccc;
    border-left: 3px solid #008542;
    color: #707070;
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 12px;
    line-height: 1.6;
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
  }
  .storybook-markdown table {
    margin-bottom: 15px;
  }
}
